import React from 'react'

import Skeleton from '@material-ui/lab/Skeleton'

import CatalogNotification from 'src/components/catalogs/Notification'

import { Grid } from '..'
import { ViewContent } from '../ResultView/ViewContent'

export default function CatalogFallback() {
  return (
    <>
      <Skeleton variant="rect" height={80} />
      <Grid>
        <Skeleton>
          <CatalogNotification />
        </Skeleton>
      </Grid>
      <Grid id="searchGrid">
        <ViewContent isSuspense={true} />
      </Grid>
    </>
  )
}
