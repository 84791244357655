import React from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles'

import { useSearchTranslation } from 'src/components/search/utils'
import { CatalogItemProps, HspDigitized } from 'src/contexts/discovery'

import ImageNotSupportedSharpSVG from './ImageNotSupported'

const useStyles = makeStyles((theme) => ({
  brokenImg: {
    alignItems: 'center',
    backgroundColor: theme.palette.white.main,
    display: 'flex',
    justifyContent: 'center',
    minHeight: 160,
    width: 120,
  },
}))

interface ThumbnailImageProps {
  digi: HspDigitized | CatalogItemProps
}

export function ThumbnailImage(props: Readonly<ThumbnailImageProps>) {
  const { searchT } = useSearchTranslation()
  const cls = useStyles()
  const { digi } = props

  return digi['thumbnail-uri-display'] ? (
    <img
      alt={searchT('resource', 'thumbnailImage')}
      src={digi['thumbnail-uri-display'] as string}
      width={120}
    />
  ) : (
    <div className={cls.brokenImg}>
      <ImageNotSupportedSharpSVG />
    </div>
  )
}
